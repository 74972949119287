<template>
    <section
        id="PoliticaPrivacidad"
    >
        <v-container>
            <div
                id="main"
                style="width: 70%; margin-left: 15%;"
            >
            <div
                class="panel-heading"
            >
                <h1>Política de cookies</h1>
            </div>
            
                <p>Mediante esta Política de Cookies, el usuario podrá conocer con qué finalidades se utilizan las cookies, dispone también de la posibilidad de configurarlas para aceptar o rechazar de forma total o parcial su utilización y además obtendrá información adicional para conocer información completa sobre su significado, configuración,finalidades y derechos.</p>
                <p>Este Sitio Web utiliza cookies propias y de terceras entidades, con la finalidad de brindar una experiencia de navegación satisfactoria para el usuario, realizar métricas y estadísticas, así como acciones de marketing. Si el usuario no está de acuerdo con alguna de las finalidades, puede personalizar las opciones desde esta política.</p>
                <p>El usuario, permite la utilización e instalación de las cookies con las siguientes finalidades:</p>
                <p>Además, este sitio web puede disponer de enlaces a sitios web de terceros, cuyas políticas de privacidad, políticas de cookies y textos legales, son ajenos al titular del presente sitio web. Al acceder a dichos sitios web mediante los mencionados enlaces,el usuario es libre de decidir si acepta o rechaza los mencionados apartados. </p>
                <p>Para acceder al panel de configuración de cookies, donde el usuario podrá aceptar o rechazar las cookies, de forma total o parcial, pulse aquí:</p>
                <v-btn
                    class="ma-2"
                    outlined
                    color="primary"
                  >
                    Panel de configuración de Cookies
                </v-btn>
                <h2><strong>1. Definición y función de las Cookies</strong></h2>
                <p>Una cookie es un pequeño archivo creado por un sitio de Internet para almacenar información en su equipo, como, por ejemplo, las preferencias cuando se visita una web. Cuando se accede a una página Web que utiliza cookies, ésta solicita al navegador que guarde una o más cookies en el disco duro. Las cookies a menudo guardan la configuración de los sitios web, como el idioma preferido o la ubicación, así,cuando el usuario vuelve a esa página Web, el navegador envía de nuevo las cookies que le pertenecen, lo que le permite prestar servicio e información personalizada en función de las necesidades. Las cookies también pueden llegar a guardar información que identifique personalmente a los usuarios, sin embargo, este sitio web no utiliza este tipo de cookies, y sólo tendrá acceso a la información personal que usted proporcione a través de los formularios de contacto o vía correo electrónico. Se puede cambiar la configuración de cookies del navegador que usted utiliza, para que le permita aprobar o rechazar automáticamente peticiones de almacenamiento de cookies cuando se cierra el navegador y otras opciones adicionales.</p>
                <h2><strong>2. Tipos de Cookies</strong></h2>
                <p>Las cookies se pueden clasificar de forma global atendiendo a tres bloques en funcióndel ámbito de estudio:</p>
                <p><strong>a) Tipos de cookies según la entidad que las gestione</strong></p>
                <p>Según quien sea la entidad que gestiona el dominio del sitio web desde donde se envían las cookies y se tratan los datos que se obtienen, se tienen:</p>
                <dl>
                    <dd>- Cookies propias: Aquéllas que se envían al dispositivo del usuario desde la propia página web que visita.</dd>
                    <dd>- Cookies de terceros: Aquéllas que se envían al dispositivo del usuario desde un dominio que no es gestionado por el titular del dominio que visita el usuario, sino por otra entidad que trata los datos obtenidos través de las cookies, como por ejemplo Google. Puesto que son estos terceros quienes proceden a su implementación, el bloqueo y desinstalación de las mismas se gestiona por sus condiciones y mecanismos específicos.</dd>
                </dl>
                <p><strong>b) Tipos de cookies según el tiempo que permanecen activas en el dispositivo:</strong></p>
                <p>Atendiendo al tiempo en el que las cookies permanecen alojadas  y activadas en el dispositivo del usuario, se tienen:</p>
                <dl>
                    <dd>- Cookies de sesión: Diseñadas para recabar y almacenar datos mientras elusuario accede a una página web. Estas cookies se almacenan en su dispositivo que finalice la sesión de navegación del usuario.</dd>
                    <dd>- Cookies persistentes: Diseñadas para permanecer activas en el dispositivo desde unos minutos, hasta varios años, y los datos siguen almacenados en el dispositivo pudiendo ser accedidos y tratados durante un periodo definido por el responsable de la cookie.</dd>
                </dl>
                <p><strong>c) Tipos de cookies según su finalidad:</strong></p>
                <p>En función del objetivo por el cual un sitio web emplea cookies, se tienen de forma genérica:</p>
                <dl>
                    <dd>- Cookies técnicas: Permiten por ejemplo conocer si el usuario ya visitó en el pasado el sitio web y aceptó o rechazó las cookies, identificándolo cuando seconecta al sitio web.</dd>
                    <dd>- Cookies de preferencias: Permiten adaptar la presentación del sitio web a las preferencias del usuario, por ejemplo, según el idioma utilizado, la resolución del dispositivo, navegador utilizado, etc. En definitiva, simplemente mejorar la experiencia de usuario.</dd>
                    <dd>- Cookies analíticas: Permiten, dependiendo de las preferencias del usuario establecer estadísticas y el volumen de visitas, tráfico web, mediciones y de uso de los diferentes elementos que forman el sitio web, al objeto de mejorar elsitio y mejora de los servicios.</dd>
                    <dd>- Cookies de marketing y/o publicitarias. Son aquellas que se utilizan de forma general para obtener un perfil de usuario para conocer sus preferencias y mostrar publicidad dirigida a la carta. Se puede crear un perfil sobre usted y sus intereses para mostrarle contenido personalizado que le fuera pertinente.</dd>
                </dl>
                <h2><strong>3. Revocación y eliminación de Cookies</strong></h2>
                <p>Con carácter general, en la navegación por internet, el usuario puede aceptar o rechazar las cookies; para permitirlas, bloquearlas, eliminarlas o desactivarlas, mediante la configuración a través del menú de opciones de su navegador.</p>
                <p>Para configurar el navegador, desactivar o administrar la instalación de cookies, y que de este modo, se borren automáticamente una vez que se cierre el navegador, equipo o dispositivo, puede obtener más información haciendo “click” en los siguientes enlaces que le proporcionamos, en función del navegador que usted utiliza:</p>
                <dl>
                    <dd>- <a href='https://support.google.com/chrome/answer/95647?hl=es'>Google Chrome</a></dd>
                    <dd>- <a href='https://support.mozilla.org/es/kb/cookies-informacion-que-los-sitios-web-guardan-en-?redirectlocale=en-US&redirectslug=Cookies'>Mozilla Firefox</a></dd>
                    <dd>- <a href='https://support.microsoft.com/es-es/help/4468242/microsoft-edge-browsing-data-and-privacy'>Edge</a></dd>
                    <dd>- <a href='https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-11'>Internet Explorer (Versión 11)</a></dd>
                    <dd>- <a href='https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-10'>Internet Explorer (Versión 10)</a></dd>
                    <dd>- <a href='https://help.opera.com/en/latest/web-preferences/'>Opera</a></dd>
                    <dd>- <a href='https://www.apple.com/legal/privacy/es/cookies/'>Safari</a></dd>
                </dl>
                <p>De forma general, la mayoría de los navegadores, permiten el acceso rápido a la eliminación de los datos de navegación, pulsando la secuencia de teclas Ctrl+Mayus+Supr, pudiéndose borrar las Cookies.</p>
                <h2><strong>4.Actualización y modificaciones en la Política de Cookies</strong></h2>
                <p>Esta Política de Cookies y Configuración, podrán ser modificadas en función de cambios legislativos o con la finalidad de adaptar dicha política a nuevas necesidades del sitio web o a nuevas instrucciones dictadas desde la Agencia Española de Protección de Datos (A.E.P.D).</p>
                <p>Última actualización: 1 de octubre de 2020</p>
            </div>
        </v-container>
</section>
</template>
<script>
  export default {
    name: 'PoliticaPriv',
    metaInfo: { title: 'Política de privacidad' },
    data: () => ({
    }),
  }
</script>

<style>
p { font-size: 120% }
dd { margin-left: 3%; font-size: 120% }
h1 { 
    font-size: 300%;
    text-align: center;
    margin-bottom: 2%;
 }
 h2 { 
    font-size: 200%;
    margin-left: -4%;
 }
</style>
